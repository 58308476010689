import { withUserOnStaticPage } from 'app/hocs/withUserOnStaticPage';
import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { Main } from 'routes/landing/pages/Main';

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale!, ['common', 'header.component', 'footer.component', 'utils'])),
  },
});

export default withUserOnStaticPage(Main);
